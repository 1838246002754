@use '../../common/theme/breakpoints'

#faq
    .show-more-button                        
        padding: 15px 30px !important
        background: #262626
        
       
.faq-column:not(.faq-column:last-child)
    margin-bottom: 10px !important    

.faq-column
    box-sizing: border-box
    display: flex    

    div
        box-sizing: border-box                  

@media (min-width: map-get(breakpoints.$breakpoints, xs))
    .faq-column + .faq-column
        margin-left: 0px !important        

    .faq-row-container + .faq-row-container 
        margin-left: 0px !important

    .faq-container
        width: 100%

    .faq-column
        width: 100%
        height: auto

@media (min-width: map-get(breakpoints.$breakpoints, sm))
    .faq-row-container + .faq-row-container 
        margin-left: 10px !important
