
.bubble    
    display: block
    min-height: 200px    
    min-width: 200px
    width: 10vw
    height: 10vw             
    box-shadow: var(--shades)        
    padding: 20px !important
    transform-origin: 50% 50%
    transform-style: preserve-3d
    perspective: 1000px
    animation: transform 10s ease-in-out infinite both alternate, movement 10s ease-in-out infinite both
        
        

@keyframes transform
    0%, 100%
        border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%
    14%
        border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%
    28%
        border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%
    42%
        border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%
    56%
        border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%
    70%
        border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%
    84%
        border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%

    @keyframes movement
    0%, 100%
        transform: translateY(-20%) rotateY(-10deg)
    50%
        transform: translateY(10%) rotateY(10deg)

:root
  --background: #fff
  --blob: #fdfbfd
  --shades: inset 10px 0 40px #f7f8fc,inset -10px 0 20px #f7e1ef,inset -40px 10px 100px #c3c5ea
            
            
  
  @media (prefers-color-scheme: dark)
    --background: #0f0c24
    --blob: #bca5e3
    --shades: inset 10px 0 40px #20CDB3,inset -10px 0 20px #20cd90,inset -40px 10px 110px #074D4B
              
              
