footer 
    background: rgb(8,200,117)
    background: -moz-linear-gradient(180deg, rgba(8,200,117,1) 0%, rgba(40,151,158,1) 100%)
    background: -webkit-linear-gradient(180deg, rgba(8,200,117,1) 0%, rgba(40,151,158,1) 100%)
    background: linear-gradient(180deg, rgba(8,200,117,1) 0%, rgba(40,151,158,1) 100%)
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#08c875",endColorstr="#28979e",GradientType=1)    

    a
        cursor: pointer
        &:hover
            color: white !important

    a
        text-decoration: none
        color: black
        font-weight: 700

    .footer-logo
        hr
            border: 2px solid black
            height: 30px
            border-radius: 30px