@use '../../common/theme/_breakpoints'

.loanList

    
        
    background: rgb(15,124,97)
    background: -moz-linear-gradient(0deg, rgba(15,124,97,1) 0%, rgba(29,45,42,1) 92%)
    background: -webkit-linear-gradient(0deg, rgba(15,124,97,1) 0%, rgba(29,45,42,1) 92%)
    background: linear-gradient(0deg, rgba(15,124,97,1) 0%, rgba(29,45,42,1) 92%)
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0f7c61",endColorstr="#1d2d2a",GradientType=1)

    .loan-card-container
        position: relative
        height: 250px !important
        width: 100% !important

        padding: 10px !important
        box-sizing: border-box
        display: flex
        flex-direction: column

        .card-plus
            position: absolute
            top: -25px
            right: 15px
            opacity: .6

    .first-card
        height: 230px

    .loan-card
        background: rgb(12, 174, 131)
        display: flex !important
        justify-content: center !important
        align-items: center !important
        border-radius: 20px
        box-shadow: 0 0 0 10px 10px black
        z-index: 100

        img:not(first-child)
            opacity: 1
            height: 100px !important

        strong
            position: absolute
            bottom: 30px
            opacity: .8

        & > div
            box-shadow: 0 0 0 10px 10px black

            box-sizing: border-box
            display: flex
            flex-direction: column
            align-items: center !important
            justify-content: center !important

    .loan-card-back
        background: rgb(9,147,110)
        background: -moz-linear-gradient(0deg, rgba(9,147,110,1) 0%, rgba(6,232,161,1) 100%)
        background: -webkit-linear-gradient(0deg, rgba(9,147,110,1) 0%, rgba(6,232,161,1) 100%)
        background: linear-gradient(0deg, rgba(9,147,110,1) 0%, rgba(6,232,161,1) 100%)
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#09936e",endColorstr="#06e8a1",GradientType=1)
        border-radius: 20px

    .swiper
        margin: 100px auto !important
        width: 300px
        height: 450px
        overflow: hidden !important

        .swiper-pagination
            position: absolute !important
            z-index: 1000 !important
            bottom: 0px !important

        .swiper-pagination-bullet

            opacity: .5 !important
            width: 5px !important
            height: 5px !important

            margin: 3px !important

        .swiper-pagination-bullet-active
            background: rgb(102, 255, 214)
            height: 10px !important
            width: 10px !important
            transform: translateY(2px)

        .swiper-slide
            height: 400px !important
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            font-size: 22px !important
            font-weight: bold !important
            color: #fff !important
            background: rgb(9,147,110)
            background: -moz-linear-gradient(0deg, rgba(9,147,110,1) 0%, rgba(6,232,161,1) 100%)
            background: -webkit-linear-gradient(0deg, rgba(9,147,110,1) 0%, rgba(6,232,161,1) 100%)
            background: linear-gradient(0deg, rgba(9,147,110,1) 0%, rgba(6,232,161,1) 100%)
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#09936e",endColorstr="#06e8a1",GradientType=1)

            border-radius: 20px

            a
                display: block
                poisiton: relative
                color: white

                img
                    width: 150px !important
                    height: auto !important

                img:first-of-type
                    position: absolute
                    top: 20px
                    right: 20px
                    height: 25px !important
                    width: 25px !important
                    opacity: .8

                strong
                    position: absolute
                    bottom: 50px !important
                    left: 50%
                    transform: translateX(-50%)
                    font-size: 30px !important
                    opacity: .8

        //margin: 10px !important

@media (min-width: map-get(breakpoints.$breakpoints, xs))
    .loanList
        h3 
            margin-left: 35px !important
@media (min-width: map-get(breakpoints.$breakpoints, sm))
    .loanList
        h3 
            margin-left: 0 !important