// Breakpoints
@use '_breakpoints'

// Colors

$colors: ('white': #ffffff, 'gray': #F4F4F4, 'dark-gray': #1A2529, 'light-green': #CDF3E5, 'green': #02C37E, 'black': #0000)

$font-sizes: (font-xs:16px, font-sm:18px, font-md:27px, font-lg:35px, font-xl:60px, font-xxl:80px)

$margins: (m-0: 0, m-10: 10px, m-25: 25px, m-50: 50px, m-100: 100px, m-150: 150px, m-200: 200px, m-250: 250px, m-300: 300px)
$paddings: (p-0: 0, p-10: 10px, p-25: 25px, p-50: 50px, p-100: 100px, p-150: 150px, p-200: 200px, p-300: 300px, p-350: 350px)

@each $breakpointName, $breakpoint in breakpoints.$breakpoints
    @each $marginName, $margin in $margins        
        @media (min-width: map-get(breakpoints.$breakpoints, $breakpointName))
            .#{$breakpointName}-#{$marginName}
                margin: $margin !important                
            
            .#{$breakpointName}-#{$marginName}-t
                margin-top: $margin !important  
                
            .#{$breakpointName}-#{$marginName}-b
                margin-bottom: $margin !important 
                

            .#{$breakpointName}-#{$marginName}-l
                margin-left: $margin !important                

            .#{$breakpointName}-#{$marginName}-r
                margin-right: $margin !important 

@each $breakpointName, $breakpoint in breakpoints.$breakpoints
    @each $paddingName, $padding in $paddings        
        @media (min-width: map-get(breakpoints.$breakpoints, $breakpointName))
            .#{$breakpointName}-#{$paddingName}
                padding: $padding !important                
            
            .#{$breakpointName}-#{$paddingName}-t
                padding-top: $padding !important  
                
            .#{$breakpointName}-#{$paddingName}-b
                padding-bottom: $padding !important 
                

            .#{$breakpointName}-#{$paddingName}-l
                padding-left: $padding !important                

            .#{$breakpointName}-#{$paddingName}-r
                padding-right: $padding !important 
  
@each $colorName, $color in $colors
    .bg-#{$colorName} 
        background-color: $color

@each $font-size-name, $font-size in $font-sizes 
    .#{$font-size-name}
        font-size: $font-size !important

@each $breakpointName, $breakpoint in breakpoints.$breakpoints
    @each $font-size-name, $font-size in $font-sizes       
        @media (min-width: map-get(breakpoints.$breakpoints, $breakpointName))
            .#{$breakpointName}-#{$font-size-name}
                font-size: $font-size !important     

@each $colorName, $color in $colors
    .font-#{$colorName} 
        color: $color

.text-center
    text-align: center

.bold-1
    font-weight: 300

.bold-2
    font-weight: 400

.bold-3
    font-weight: 700

.bold-4
    font-weight: 800

b
    color: map-get($colors, 'green')
// OTHER
button
    cursor: pointer
    
html 
    overflow-x: hidden

nav
    position: fixed
    z-index: 1
    top: 0
    left: 0
    width: 100%

body 
    font-family: 'Nunito', sans-serif
    position: relative
    display: flex    
    flex-direction: column
    min-height: 100vh
    
    margin: 0 !important       
    overflow-x: hidden

    -ms-overflow-style: none
    scrollbar-width: none    

    &::-webkit-scrollbar 
        display: none

    *
        margin: 0 !important
        padding: 0 !important  

        &:focus 
            outline: none !important  


.no-scroll
    height: 100vh
    overflow: hidden

.no-border
    border: none 

.border
    border: 1px solid map-get($colors, 'green') !important

.container
    width: 100%
    display: flex
    justify-content: center 
    align-items: center
    box-sizing: border-box

// FLEX

.justify-center        
    justify-content: center !important

.justify-right        
    justify-content: flex-end !important

.justify-left        
    justify-content: flex-start !important

.justify-space-between
    justify-content: space-between !important

.align-items-right
    align-items: flex-end !important

.align-items-left
    align-items: flex-start !important

.align-items-center
    align-items: center !important

// GRID

.row
    display: flex 

.static-row
    display: flex     
    align-items: center

.column
    flex: 1
    display: flex
    flex-direction: column

.relative 
    position: relative
    
.full-width
    width: 100%

.full-height
    height: 100% !important

// BORDERS

.rounded-1
    border-radius: 15px

.rounded-2
    border-radius: 25px

.rounded-3
    border-radius: 50px


// DROPDOWN

.dropdown-heading
    h4
        background: red !important
    

.dropdown-row-closed 
    max-height: 0px
    overflow: hidden
    transition: all .5s

.dropdown-row-open
    max-height: 500px
    overflow: hidden
    transition: all .5s

.dropdown-btn-open
    border: none
    background: none
    margin: 0 !important
    padding: 0 !important
    width: auto !important
    height: auto !important
    color: white
    position: absolute
    right: 5%
    top: 50%
    transform-origin: center
    transform: translateY(-50%)
    transition: all .5s

.title, .content-block h3
    font-size: 40px
    font-weight: 800
    position: relative        
    margin-left: 15px !important     
    &::after
            content: ''
            display: block
            background-color: map-get($colors, 'green')
            position: absolute
            width: 5px
            height: 40px
            left: -20px            
            top: 7px
            z-index: 1
            border-radius: 20px



// INPUT

textarea
    resize: none    
    box-sizing: border-box


    

.data-input

    
    label
        font-weight: 500

    input, textarea, select
        border: 1px solid map-get($colors, 'green')
        padding: 10px !important
        border-radius: 15px
        background: map-get($colors, 'gray')

    select        
        -webkit-box-sizing: border-box
        -moz-box-sizing: border-box
        box-sizing: border-box
        -webkit-appearance: none
        -moz-appearance: none

        background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc)
        background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em
        background-size: 5px 5px, 5px 5px, 1px 1.5em
        background-repeat: no-repeat


    input[type='range']
        -webkit-appearance: none        
        height: 8px !important
        padding: 0 !important
        background: black 
        border: none !important
        border-radius: 30px
        outline: none
    
        -webkit-transition: .2s        

        &::-webkit-slider-thumb
            position: relative
            z-index: 10
            -webkit-appearance: none
            appearance: none
            width: 30px
            height: 30px
            background: rgb(0,151,178)
            background: -moz-linear-gradient(138deg, rgba(0,151,178,1) 0%, rgba(126,217,87,1) 100%)
            background: -webkit-linear-gradient(138deg, rgba(0,151,178,1) 0%, rgba(126,217,87,1) 100%)
            background: linear-gradient(138deg, rgba(0,151,178,1) 0%, rgba(126,217,87,1) 100%)
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0097b2",endColorstr="#7ed957",GradientType=1)
            border: 2px solid white
            border-radius: 15px
            box-shadow: 0 0 10px rgba(0, 0, 0, .2)
            cursor: pointer

    div
        margin-top: 10px !important

         

.radio-container     
    min-width: 20px !important
    height: 20px !important
    border: 2px solid map-get($colors, 'green')    
    border-radius: 50%
    background:  map-get($colors, 'gray')
    position: relative
    cursor: pointer

    &[data-isOn="true"]             
        &::after 
            content: ''           
            display: block
            height: 11px
            width: 11px
            position: absolute
            background: map-get($colors, 'green')
            left: 50%
            top: 50%
            transform: translate(-50%, -50%)
            border-radius: 50%
            
            
            

               

                        
img 
    pointer-events: none

footer
    position: absolute
    bottom: 0
    left: 0
    width: 100%

.desktop
    display: none

.no-bg
    background: none !important

.pointer 
    cursor: pointer !important

@each $colorName, $color in $colors
    .border-#{$colorName} 
        border: 1px solid $color

@media (min-width: map-get(breakpoints.$breakpoints, xs))
    .title, .content-block h3         
        font-size: 30px !important  

        margin-left: 15px !important     
        &::after
                top: 5px !important
                left: -15px
                width: 5px
                height: 30px

    

    .header
        hegith: auto !important

    .content-block 
        padding: 70px 10px !important

    .container-content
        width: 100%
    
    .row
        flex-direction: column

    .p-50 
        &-xs 
            padding: 50px

    .desktop
        display:'none' !important   

    footer
        position: relative
        bottom: 0
        left: 0
        width: 100%        
    


@media (min-width: map-get(breakpoints.$breakpoints, sm))    
    footer
        position: absolute
        bottom: 0
        left: 0
        width: 100%

    .data-option        
        //background: map-get($colors, 'gray')
        //border: 1px solid map-get($colors, 'green')        
        width: 100%
        height: auto !important

    .title, .content-block h3 
        margin-left: 20px !important 
        font-size: 45px !important

        &::after
            top: 5px !important
            left: -15px
            width: 5px
            height: 45px  

    .header
        height: 95vh !important

    .content-block 
        padding: 150px 0 !important
        
    .container-content
        width: 540px        

    .row
        flex-direction: row
    
    .mobile
        display: none

    .desktop
        display: flex !important

@media (min-width: map-get(breakpoints.$breakpoints, md))
    .data-option  
        height: 150px !important

    .container-content
        width: 95%
        transform: scale(1)

    .mobile
        display: none

@media (min-width: map-get(breakpoints.$breakpoints, lg))
    .container-content
        width: 960px

    .mobile
        display: none

@media (min-width: map-get(breakpoints.$breakpoints, xl))
    .container-content
        width: 1140px

    .mobile
        display: none

@media (min-width: map-get(breakpoints.$breakpoints, xxl))
    .container-content
        width: 1320px  

    .mobile
        display: none          