.switch 
    width: 70px
    height: 40px
    background-color: rgba(187, 187, 187, 0.4)
    display: flex
    justify-content: flex-start
    border-radius: 50px
    align-items: center
    padding: 0 7px !important
    cursor: pointer
    box-sizing: border-box
    transition: all .2s

    &[data-isOn="true"] 
        justify-content: flex-end
        background-color: #3AB588
        transition: all .2s
    

.handle 
    width: 30px
    height: 30px
    background-color: white
    border-radius: 40px
    box-sizing: border-box !important
    