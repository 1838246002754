@use '../../common/theme/_breakpoints'
    
#contact
    form 
        position: relative


        *            
            margin: 0 !important
        
        input, textarea
            background: white
            border: none

        label
            margin-bottom: 5px !important
            margin-top: 10px !important

    
    button
        position: absolute
        bottom: 0
        left: 50%        
        transform: translate(-50%, 50%)
        padding: 15px !important
        width: 50%


@media (min-width: map-get(breakpoints.$breakpoints, xs))
    #contact
        form
            padding-bottom: 50px !important

@media (min-width: map-get(breakpoints.$breakpoints, sm))        
    #contact
        form
            paddding-bottom: 0px !important