@use '../../common/theme/_breakpoints'
    
#header 
    background: rgb(0,179,171)
    background: -moz-linear-gradient(90deg, rgba(0,179,171,1) 0%, rgba(161,232,77,1) 100%)
    background: -webkit-linear-gradient(90deg, rgba(0,179,171,1) 0%, rgba(161,232,77,1) 100%)
    background: linear-gradient(90deg, rgba(0,179,171,1) 0%, rgba(161,232,77,1) 100%)
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00b3ab",endColorstr="#a1e84d",GradientType=1)
    overflow: hidden
    height: 95vh !important    

    .mobile > div
        background: rgba(255, 255, 255, .5)
        border-radius: 100px !important
        margin-bottom: 1px !important    
            

    .header-image
        width: 100%
        right: 0
        //transform: translateX(50%)
        position: relative
        z-index: -10
        transform: scale(2)
        z-index: -1
        top: 0
        

        

    #down-button
        position: absolute
        z-index: 1
        bottom: 0
        left: 50%
        height: 50px
        width: 50px
        font-size: 50px
        display: flex
        align-items: center
        justify-content: center
        padding-bottom: 13px !important        
        border: none
        background: none
        color: white
        opacity: .8
        transform: rotate(-90deg) translateY(-50%)
        animation: MoveUpDown 1s linear infinite

    .header-separator
        position: absolute
        z-index: 0
        left: 0
        top: 0
        height: 100%
        width: 100%
        background: rgb(255,255,255)
        background: -moz-linear-gradient(294deg, rgba(255,255,255,0.3) 55%, rgba(255,255,255,0) 55%)
        background: -webkit-linear-gradient(294deg, rgba(255,255,255,0.3) 55%, rgba(255,255,255,0) 55%)
        background: linear-gradient(294deg, rgba(255,255,255,0.3) 55%, rgba(255,255,255,0) 55%)
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1)

@keyframes MoveUpDown 
    0%, 100% 
        bottom: 0

    50% 
        bottom: 10px
  
@media (min-width: map-get(breakpoints.$breakpoints, xs))
             

    #header 
        .calculator-container            
            position: relative    
            width: auto !important        
            

        height: auto !important
        h1
            min-height: 100px !important
            font-size: 33px !important
            width: 360px !important
            line-height: 40px !important
            font-weight: 600 !important


@media (min-width: map-get(breakpoints.$breakpoints, sm))
    #header
        
            

        .checkboxes
            position: absolute 
            bottom: 100px !important

        .calculator-container            
            position: absolute
            bottom: 100px !important

        min-height: auto !important
        padding: 100px 0px !important   

        h1
            position: absolute
            top: 0
            font-size: 50px !important
            line-height: 50px !important
            width: 50% !important