#faqPage
    .accordion-content
        

    .accordion
        line-height: 50px
        font-weight: 500

    

        button
            color: gray
            right: 0

        
        &:hover
            border-bottom: 1px solid gray
