@use '../../common/theme/_breakpoints'
            
    
.nav-main.nav-main-active 
    #home
        overflow: visible
        width: 85px !important
        transition: all .2
    
    //transform: translateY(-13%)
    transition: all .2s     

    

    .nav-logo
        transform: translateY(5px) translateX(20px)
        transition: all .3s



        img 
            height: 50px !important   
            transform: translateY(1px) scale(.8)
            transition: all .2s    

    .nav-menu
        background: none
        backdrop-filter: none
        //width: 500px
        transition: all .3s

    hr 
        height: 25px
        transform: translateX(-2px)        

.nav-menu-container
    position: absolute
    
    right: 0
    bottom: 0
    height: 61px
    width: 400px
    border-radius: 50px
    background: rgba(255, 255, 255, 0.87)
    backdrop-filter: blur(50px)
    -webkit-backdrop-filter: blur(50px)
    padding-right: 20px !important
    transition: all .3s
    //border: 2px solid rgba(0, 0, 0, .05)
    
.nav-menu-container.nav-menu-active
    right: 0
    box-sizing: border-box
    background: rgba(255, 255, 255, 0.7)
    -webkit-box-shadow: 0px 10px 17px 0px rgba(0,0,0,0.05)
    -moz-box-shadow: 0px 10px 17px 0px rgba(0,0,0,0.05)
    box-shadow: 0px 10px 17px 0px rgba(0,0,0,0.05)
        
    width: 100%    
    
    transition: all .3s    

.nav-logo
    z-index: 10   
    transition: all .3s
    img 
        height: 70px !important 
        transition: all .2s     
        
    
.nav-menu
    #home
        overflow: hidden
        width: 0px !important
        transition: all .2s

    margin-top: 10px !important
    background: rgba(255, 255, 255, 0)
    //backdrop-filter: blur(20px)
    width: 375px
    justify-content: space-between
    padding: 10px !important    
    z-index: 10
    transition: all .3s

    button
        padding: 10px 30px !important
        border: none
        font-size: 18px  !important
    
    span
        text-decoration: none
        color: black
        font-weight: 500 !important
        text-decoration: underline 0.15em #00000000
        text-underline-offset: 0.2em
        transition: text-decoration-color 300ms, text-underline-offset 300ms

        &:hover 
            color: #02C37E
            text-decoration-color: #02C37E
            text-underline-offset: 0.4em
        
       
            

    b 
        border: 1px solid #02C37E
        padding: 5px 20px !important
        border-radius: 20px

.nav-logo
    img 
        transform: translateY(10px)

    hr
        border: 2px solid black
        border-radius: 30px
        height: 30px
        

nav
    background: rgba(255, 255, 255, 0)
    transition: all .2s


.mobile-menu-container
    position: fixed !important
    left: 50%
    transform: translateX(-50%)
    top: 71px
    width: 100% !important
    height: 0px !important
    overflow: hidden !important
    background: red
    z-index: 9999
    box-sizing: border-box
    padding: 0px !important
    transition: all .2s !important
    background: rgba(245, 245, 245, 0.8)
    backdrop-filter: blur(50px)
    -webkit-backdrop-filter: blur(50px)
    opacity: 0 !important
    ul
        list-style: none !important

        li
            cursor: pointer !important

        li:not(:first-child)
            margin: 20px 0px !important
            font-size: 20px !important
            font-weight: 500 !important
            text-align: center !important
            border-radius: 30px !important
            border: 1px solid rgba(0, 0, 0, .2) !important
            padding: 10px 0px !important

        button 
            width: 100% !important
            font-size: 20px !important
            border: none !important

    

    &.mobile-menu-active
        opacity: 1 !important
        padding: 50px !important
        padding-top: 30px !important
        height: 325px !important
        transition: all .2s !important
        box-shadow: 0 10px 20px rgba(0, 0, 0, .1)

.mobile-overlay
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%    
    z-index: 100


nav.mobile  
    position: fixed !important
    top: 25px
    left: 0

    *
        transition: all .2s !important  

    padding: 10px !important    
    box-sizing: border-box

    .menu-button-container        
        display: flex
        justify-content: flex-end
        position: absolute
        z-index: 0
        right: 10px        
        width: 50px !important
        border-radius: 30px
        background: rgba(255, 255, 255, .8)   
        -webkit-backdrop-filter: blur(50px) 
        backdrop-filter: blur(20px)
        
       
        transition: all .2s !important
        
        
        

    .menu-button
        
        display: flex !important
        align-items: center !important
        justify-content: center !important
        position: relative

        border: 1px solid rgba(0, 0, 0, 0) !important
        padding: 10px !important
        border-radius: 50%
        width: 50px !important
        height: 50px !important            
        z-index: 999999 !important        
        background: rgba(255, 255, 255, 1)
        cursor: pointer !important

        img
            height: 20px !important
            opacity: .8            

    //background: rgba(255, 255, 255, .7)
    background: none 
    //backdrop-filter: blur(20px)
    z-index: 9999

    .mobile-logo
        position: fixed !important
        z-index: 9999 !important

    h2
        position: relative
        
        
        font-size: 25px !important
        margin-left: 10px !important
        line-height: 25px !important
        z-index: 9999 !important

    img 
        
        height: 50px !important         
        transition: all .2s 
        margin: 0 !important  
        z-index: 999999 !important

    span
        color: white !important
        

    

    img:last-child
        filter: invert(1) !important


nav.mobile.nav-main-active
    *
        transition: all .2s !important

    .menu-button
        background: rgba(255, 255, 255, .5) 
        margin-right: 10px !important
        border: 1px solid rgba(0, 0, 0, .1) !important
        box-shadow: 0 0 10px rgba(0, 0, 0, .05)   

            //transform: scale(1.2) !important
            
        



    h2

        span
            color: #02C37E !important

    .menu-button-container 
        border-bottom: 1px solid white  
        right: 0px 
        box-shadow: 0 0 20px rgba(0, 0, 0, .1)    
        transition: all .2s !important        
        width: 110% !important
        padding: 10px 0px !important        
        border-radius: 0 !important

