.application
    background: rgba(15, 124, 97, 0.1)
    

    
    p
        color: rgba(0, 0, 0, 0.7)

    button
     
        padding: 15px !important            

    .amount-input 
        text-align: center !important
        background: none
        font-weight: bold !important
        font-size: 15px !important

button
    padding: 15px !important    