@use '../../common/theme/_breakpoints'
    
.roadmap     
    .steps
        p
            text-align: center
            margin-top: 20px !important

    img
        height: 40px
        margin-bottom: 25px !important

    button 
        padding: 15px 30px !important
        
     
    
.step-number 
    border-radius: 50%
    height: 30px
    width: 30px        


@media (min-width: map-get(breakpoints.$breakpoints, xs))
    .roadmap
        .rounded-3:not(button)
            border-radius: 20px !important

        .roadmap-container
            flex-direction: column !important            
            
            .arrow-next
                img                                         
                    transform: translate(-7px, -11px) rotate(90deg)

            
            .sequence-step    
                p
                    text-align: left

                div:first-child
                    display: flex !important
                    flex-direction: row-reverse !important

                    div 
                        margin-right: 20px !important
                
                flex-direction: row !important
                justify-content: center !important
                align-items: center !important                             
                margin-bottom: 50px !important

                img
                    margin: 0 !important
                    margin-right: 10px !important

                p
                    margin: 0 !important

@media (min-width: map-get(breakpoints.$breakpoints, sm))
    .roadmap
        padding-bottom: 100px !important
        .roadmap-container
            .arrow-next
                img                                         
                    transform: none


            flex-direction: row !important

            .sequence-step                    
                .step-number                                     
                    margin: 20px !important
                    width: 50px !important
                    height: 50px !important
                    font-size: 20px !important

                p
                    text-align: center


                div:first-child
                    display: flex !important
                    flex-direction: column !important

                    
                        
                
                flex-direction: column !important
                justify-content: center !important
                align-items: center !important    
                margin: 0 !important                                         

                img
                    margin: 0 !important
                    

                p
                    margin: 0 !important
                    