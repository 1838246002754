@use '../../common/theme/_breakpoints'


@media (min-width: map-get(breakpoints.$breakpoints, xs))
    #loanFor
        background: rgb(29,45,42)
        background: -moz-linear-gradient(0deg, rgba(29,45,42,1) 0%, rgba(0,159,109,1) 100%)
        background: -webkit-linear-gradient(0deg, rgba(29,45,42,1) 0%, rgba(0,159,109,1) 100%)
        background: linear-gradient(0deg, rgba(29,45,42,1) 0%, rgba(0,159,109,1) 100%)
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1d2d2a",endColorstr="#009f6d",GradientType=1)

        border-bottom

        background: black

        h2
            line-height: 60px !important

        .loan-for-background
            position: absolute
            left: 0
            top: 0
            width: 100%
            height: 100%      
            background-size: cover !important              

        #business-background
            background: url(../../common/img/webp/Business.webp) no-repeat center center
            opacity: .4
            filter: contrast(.4)

        #elektronik-background
            background: url(../../common/img/webp/Elektronik.webp) no-repeat center center
            opacity: .4
            filter: contrast(.4)

        #immobilien-background
            background: url(../../common/img/webp/Immobilien.webp) no-repeat center center
            opacity: .4
            filter: contrast(.4)
        
        #bildung-background
            background: url(../../common/img/webp/Bildung.webp) no-repeat center center
            opacity: .4
            filter: contrast(.4)
        
        #reisen-background
            background: url(../../common/img/webp/Reisen.webp) no-repeat center center
            opacity: .4
            filter: contrast(.4)

        #fahrzeuge-background
            background: url(../../common/img/webp/Fahrzeuge.webp) no-repeat center center
            opacity: .4
            filter: contrast(.4)

        #renovation-background
            background: url(../../common/img/webp/Immobilien.webp) no-repeat center center
            opacity: .4
            filter: contrast(.4)
                
            
            
            
            
            
            
            
                   
            
        .card
            position: relative
            background: red
            height: 400px !important
            width: 300px !important
            margin-bottom: 100px !important
            border-radius: 30px !important

            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            font-size: 22px !important
            font-weight: bold !important
            color: #fff !important
            background: rgb(9,147,110)
            background: -moz-linear-gradient(0deg, rgba(9,147,110,1) 0%, rgba(6,232,161,1) 100%)
            background: -webkit-linear-gradient(0deg, rgba(9,147,110,1) 0%, rgba(6,232,161,1) 100%)
            background: linear-gradient(0deg, rgba(9,147,110,1) 0%, rgba(6,232,161,1) 100%)
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#09936e",endColorstr="#06e8a1",GradientType=1)

            img:first-of-type
                position: absolute
                top: 20px
                right: 20px
                height: 25px !important
                width: 25px !important
                opacity: .8

            img
                width: 150px !important
                height: auto !important

            strong
                position: absolute
                bottom: 50px !important
                font-size: 30px !important
                opacity: .81


