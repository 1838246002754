@use '../../common/theme/_breakpoints'
    
.calculator
    

    .font-xs
        font-size: 16px !important     
        white-space: nowrap 

    #chf-input
        position: absolute
        z-index: 1
        top: 33px
        left: 12px
        font-weight: 700        

    position: relative
    //top: -250px
    
    background-color: rgba(255, 255, 255, 0.9)   
    backdrop-filter: blur(20px)
    -webkit-backdrop-filter: blur(20px)
    //box-shadow: 0 0 50px rgba(0, 0, 0, .2)

    border: 1px solid rgba(255, 255, 255, 0.4)
    width: 650px !important 
    height: 90px !important

    input 
        border: 1px solid rgba(0, 0, 0, 0.1)
        height: 35px   
        border-radius: 10px
        box-sizing: border-box
        width: 100%
        margin: 0px !important

    h3
        margin-bottom: 5px !important

    button
        
        position: absolute
        bottom: 0
        left: 50%
        transform: translate(-50%, 50%)
        padding: 15px !important
        color: white
        border: 1px solid rgba(255, 255, 255, .3)
        width: 50%    

    strong
        margin: 0 !important
        

    hr
        border: 1px solid black
        position: absolute
        left: 50%
        top: 0        
        height: 100%
        opacity: .3

    .antrag-button 
        transition: all .2s
        &:hover 
            background: #02C37E
            font-size: 19px !important
            transition: all .2s
            box-sizing: border-box
            padding: 20px !important
            

@media (min-width: map-get(breakpoints.$breakpoints, xs))
    .calculator
        width: 100% !important 
        height: 230px !important
        text-align: center !important
        box-sizing: border-box        
        bottom: 0 !important                

        
            

        hr
            height: 1px !important
            width: 100% !important
            left: 0 !important
            top: 50% !important
            background: black
            border: none

        button
            width: 70% !important

        div
            align-items: center !important
@media (min-width: map-get(breakpoints.$breakpoints, sm))
    .calculator
        min-width: 600px !important 
        height: auto !important
        
        padding-bottom: 50px !important

        .input-container
            height: 60px !important            
                
          

        button
            width: 50% !important

        div
            display: flex
            align-items: left !important

        hr
            border: 1px solid black !important
            position: absolute !important
            left: 50% !important
            top: 0 !important        
            height: 100% !important
            width: 0px !important
            opacity: .3 !important